<template>
  <!-- City Infos -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header -->
      <div class="card-header px-6" style="background-color: #18ba70">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Infos de la ville</h3>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="city" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Ville</div>
            <div v-if="city.libcom_2014" class="pt-2">
              {{ city.libcom_2014 }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Département</div>
            <div v-if="city.department" class="pt-2">
              {{ city.department }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Région</div>
            <div class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Décote prix ancien</div>
            <div v-if="city.decote_prix_ancien" class="pt-2">
              {{ city.decote_prix_ancien }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Zonage abc</div>
            <div v-if="city.zonage_abc" class="pt-2">
              {{ city.zonage_abc }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Nombre de logements ville</div>
            <div v-if="city.nombre_de_logements_ville" class="pt-2">
              {{ city.nombre_de_logements_ville }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Prix immo ancien dvf moyen</div>
            <div v-if="city.prix_immo_ancien_dvf_moyen" class="pt-2">
              {{ city.prix_immo_ancien_dvf_moyen }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Prix immo ancien 3e quartile</div>
            <div v-if="city.prix_immo_ancien_3e_quartile" class="pt-2">
              {{ city.prix_immo_ancien_3e_quartile }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Nb de transactions</div>
            <div v-if="city.nb_de_transactions" class="pt-2">
              {{ city.nb_de_transactions }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Prix de reference hlm ht</div>
            <div v-if="city.prix_de_reference_hlm_ht" class="pt-2">
              {{ city.prix_de_reference_hlm_ht }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">
              Premiere estimation valeur social ht
            </div>
            <div v-if="city.premiere_estimation_valeur_social_ht" class="pt-2">
              {{ city.premiere_estimation_valeur_social_ht }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Seuil social logements</div>
            <div v-if="city.seuil_social_logements" class="pt-2">
              {{ city.seuil_social_logements }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Seuil social m2</div>
            <div v-if="city.seuil_social_m2" class="pt-2">
              {{ city.seuil_social_m2 }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Taux lls commune</div>
            <div v-if="city.taux_lls_commune" class="pt-2">
              {{ city.taux_lls_commune }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Politic infos -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="card-header px-6 bg-primary">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Infos politiques</h3>
        </div>
        <div class="d-flex justify-content-end">
          <div class="d-flex align-items-center">
            <span
              class="text-white"
              data-bs-toggle="modal"
              data-bs-target="#editCityPoliticModal"
              style="cursor: pointer"
              @click.prevent="openEditMenu()"
            >
              Modifier
            </span>
          </div>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="city" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Nom du maire</div>
            <div v-if="city.mayor_name" class="pt-2">
              {{ city.mayor_name }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Profession du maire</div>
            <div v-if="city.mayor_position" class="pt-2">
              {{ getUserPosition(city.mayor_position) }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Parti politique</div>
            <div v-if="city.political_orientation" class="pt-2">
              {{ city.political_orientation }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div>
            <div class="fw-bolder text-black">Date d'élection</div>
            <div v-if="city.election_date" class="pt-2">
              {{ getDate(city.election_date) }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>

  <!-- Soho infos -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="card-header px-6" style="background-color: #33d73e">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Relation Soho</h3>
        </div>
        <div class="d-flex justify-content-end">
          <div class="d-flex align-items-center">
            <span
              class="text-white"
              data-bs-toggle="modal"
              data-bs-target="#editSohoRelationModal"
              style="cursor: pointer"
              @click.prevent="openEditMenu()"
            >
              Modifier
            </span>
          </div>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="city" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Client 1loyermoinscher</div>
            <div v-if="city.is_1loyermoinscher_customer !== null" class="pt-2">
              {{ city.is_1loyermoinscher_customer ? "Oui" : "Non" }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Client préemption</div>
            <div v-if="city.is_preemption_customer !== null" class="pt-2">
              {{ city.is_preemption_customer ? "Oui" : "Non" }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div>
            <div class="fw-bolder text-black">Patrimoine sur la commune</div>
            <div
              v-if="city.has_patrimoine_sur_la_commune !== null"
              class="pt-2"
            >
              {{ city.has_patrimoine_sur_la_commune ? "Oui" : "Non" }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <div
    id="editCityPoliticModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="editCityPoliticModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">
            Modifier les infos politiques
          </h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form @submit.prevent="updateInfos()">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Nom du maire</label>
                <input
                  v-model="form.mayor_name"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Profession du maire</label>
                <select
                  v-if="cityPositions"
                  v-model="form.mayor_position"
                  class="form-select form-select-solid"
                >
                  <option
                    v-for="position in cityPositions"
                    :key="position.id"
                    :value="position.id"
                  >
                    {{ position.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Parti politique</label>
                <select
                  v-model="form.political_orientation"
                  class="form-select form-select-solid"
                >
                  <option value="Extrême droite">Extrême droite</option>
                  <option value="Droite">Droite</option>
                  <option value="Centre">Centre</option>
                  <option value="Gauche">Gauche</option>
                  <option value="Extrême gauche">Extrême gauche</option>
                </select>
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Date d'élection</label>
                <input
                  v-model="form.election_date"
                  type="date"
                  class="form-control form-control-solid mb-3"
                  rows="3"
                />
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              :disabled="
                form.mayor_name !== '' &&
                form.mayor_position !== '' &&
                form.political_orientation !== '' &&
                form.election_date !== ''
                  ? false
                  : true
              "
            >
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    id="editSohoRelationModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="editSohoRelationModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">
            Modifier la relation SOHO
          </h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form @submit.prevent="updateInfos()">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Client 1loyermoinscher</label>
                <select
                  v-model="form.is_1loyermoinscher_customer"
                  class="form-select form-select-solid"
                >
                  <option :value="true">Oui</option>
                  <option :value="false">Non</option>
                </select>
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Client préemption</label>
                <select
                  v-model="form.is_preemption_customer"
                  class="form-select form-select-solid"
                >
                  <option :value="true">Oui</option>
                  <option :value="false">Non</option>
                </select>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-12 fv-row">
                <label class="fs-5 fw-bold mb-2"
                  >Patrimoine sur la commune</label
                >
                <select
                  v-model="form.has_patrimoine_sur_la_commune"
                  class="form-select form-select-solid"
                >
                  <option :value="true">Oui</option>
                  <option :value="false">Non</option>
                </select>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              :disabled="
                form.has_patrimoine_sur_la_commune !== '' &&
                form.is_1loyermoinscher_customer !== '' &&
                form.is_preemption_customer !== ''
                  ? false
                  : true
              "
            >
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import moment from "moment";

export default {
  name: "CityDetails",
  components: {
    Loader,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        id: this.id,
        mayor_name: "",
        mayor_position: "",
        political_orientation: "",
        election_date: "",
        is_1loyermoinscher_customer: "",
        is_preemption_customer: "",
        has_patrimoine_sur_la_commune: "",
      },
    };
  },
  computed: {
    ...mapGetters(["city"]),
    ...mapGetters(["cityPositions"]),
  },
  mounted() {
    this.showCity(this.id);
    this.getCityPositions();
  },
  methods: {
    ...mapActions(["getCity"]),
    ...mapActions(["getCityPositions"]),
    ...mapActions(["updateCity"]),
    showCity(id) {
      this.getCity(id);
    },
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    openEditMenu() {
      if (this.city) {
        this.form = {
          id: this.id,
          mayor_name: this.city.mayor_name ? this.city.mayor_name : "",
          mayor_position: this.city.mayor_position
            ? this.city.mayor_position
            : "",
          political_orientation: this.city.political_orientation
            ? this.city.political_orientation
            : "",
          election_date: this.city.election_date ? this.city.election_date : "",
          is_1loyermoinscher_customer:
            this.city.is_1loyermoinscher_customer !== null
              ? this.city.is_1loyermoinscher_customer
              : "",
          is_preemption_customer:
            this.city.is_preemption_customer !== null
              ? this.city.is_preemption_customer
              : "",
          has_patrimoine_sur_la_commune:
            this.city.has_patrimoine_sur_la_commune !== null
              ? this.city.has_patrimoine_sur_la_commune
              : "",
        };
      }
    },
    async updateInfos() {
      const res = await this.updateCity(this.form);
      if (res) {
        this.showCity(this.id);
      }
    },
    getUserPosition(id) {
      if (this.cityPositions) {
        let position = null;
        for (let key in this.cityPositions) {
          if (this.cityPositions[key].id === id) {
            position = this.cityPositions[key];
          }
        }
        return position.title;
      }
    },
  },
};
</script>
