<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid mb-12">
    <!-- Header -->
    <div v-if="city" class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            Mairie de {{ city.libcom_2014 }}
          </h1>
        </div>
        <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
          <star-rating
            :star-size="40"
            :rating="form.rating"
            @update:rating="setCityRating"
          />
          <button
            v-if="form.rating > 0"
            class="btn btn-color-danger"
            @click.prevent="setCityRating(0)"
          >
            <i class="bi bi-x-circle-fill" style="font-size: 1.2rem"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <Loader :data="city" />
    <!-- Body -->
    <div v-if="city" class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
          <CityDetails :id="city.id" />
          <CityContacts :id="city.id" />
          <CityCallbackDates :id="city.id" @refresh-data="showCity(id)" />
          <CityComments :id="city.id" @refresh-data="showCity(id)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
import CityDetails from "@/components/cities/CityDetails";
import CityContacts from "@/components/cities/CityContacts";
import CityCallbackDates from "@/components/cities/CityCallbackDates";
import CityComments from "@/components/cities/CityComments";
import StarRating from "vue-star-rating";

export default {
  name: "CitiesInfos",
  components: {
    Loader,
    CityDetails,
    CityContacts,
    CityCallbackDates,
    CityComments,
    StarRating,
  },
  data() {
    return {
      form: {
        rating: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["city"]),
    ...mapGetters(["userAuth"]),
  },
  watch: {
    city() {
      if (this.city && this.city.mayor_relation_quality) {
        this.form.rating = this.city.mayor_relation_quality;
      }
    },
  },
  methods: {
    ...mapActions(["getCity"]),
    ...mapActions(["rateCity"]),
    showCity(id) {
      this.getCity(id);
    },
    setCityRating(rating) {
      this.form.rating = rating;
      this.form.id = this.city.id;
      this.rateCity(this.form);
      this.showCity(this.city.id);
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const route = useRoute();
    this.showCity(route.params.id);
  },
};
</script>
