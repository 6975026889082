<template>
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Commentaires</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalComments"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="cityComments" />
      <!-- Card body -->
      <div v-if="cityComments" class="card-body pt-7 h-300px overflow-scroll">
        <div
          v-if="cityComments.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div v-for="comment in cityComments.results" :key="comment.id">
            <div class="mb-3">
              <div>
                <span class="fw-bolder text-dark">Date : </span
                >{{ getMomentDate(comment.created) }}
              </div>
              <div>
                <span v-if="comment.author"
                  >Auteur : {{ comment.author.first_name }}
                  {{ comment.author.last_name }}</span
                >
                <span v-else>Auteur : aucune information</span>
              </div>
              <div>
                <span v-if="comment.content"
                  >Commentaire : {{ comment.content }}</span
                >
              </div>
              <span
                class="text-danger"
                style="cursor: pointer"
                @click.prevent="deleteComment(comment.id)"
                >Supprimer</span
              >
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-gray-700">
          Aucun commentaire associé à cette ville
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Add comment up modal -->
  <div
    id="modalComments"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalCommentsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un commentaire</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <CloseIcon />
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form @submit.prevent="postComment()">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="mb-5 fv-row">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Commentaire</span>
              </label>
              <textarea
                v-model="form.content"
                class="form-control form-control-solid mb-3"
                rows="3"
              ></textarea>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Loader from "@/components/Loader";
import CloseIcon from "@/components/icons/CloseIcon";

export default {
  name: "CityComments",
  components: {
    Loader,
    CloseIcon,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        id: null,
        content: "",
        city: this.id,
        author: "",
      },
    };
  },
  computed: {
    ...mapGetters(["cityComments"]),
  },
  mounted() {
    this.getComments();
  },
  methods: {
    ...mapActions(["getCityComments"]),
    ...mapActions(["postCityComment"]),
    ...mapActions(["deleteCityComment"]),

    async getComments() {
      await this.getCityComments(this.id);
    },
    async postComment() {
      await this.postCityComment(this.form);
      await this.getCityComments(this.id);
    },
    async deleteComment(commentId) {
      await this.deleteCityComment(commentId);
      this.getCityComments(this.id);
    },

    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
  },
};
</script>
