<template>
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Contacts</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-primary"
            data-bs-toggle="modal"
            data-bs-target="#addContactSelectModal"
            @click.prevent="cleanForm()"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="cityContacts" />
      <!-- Card body -->
      <div v-if="cityContacts" class="card-body pt-7 h-300px overflow-scroll">
        <div
          v-if="cityContacts.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div v-for="contact in cityContacts.results" :key="contact.id">
            <div class="mb-4 d-flex justify-content-between align-items-center">
              <div>
                <span class="fw-bolder text-black">
                  {{ contact.last_name }} {{ contact.first_name }}
                </span>
                <div v-if="contact.position && cityPositions">
                  Poste :
                  {{ getUserPosition(contact.position) }}
                </div>
                <div v-if="contact.phone_number">
                  Téléphone : {{ contact.phone_number }}
                </div>
                <span class="text-gray-700">
                  <star-rating
                    :star-size="15"
                    :rating="contact.relation_quality"
                    @update:rating="updateContact($event, contact.id)"
                  />
                </span>
              </div>
              <div>
                <span
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#addContactSelectModal"
                  @click.prevent="setUpdateContactValues(contact)"
                >
                  <i class="bi bi-pencil-fill" style="font-size: 1.2rem"></i>
                </span>
                <span class="btn" @click.prevent="deleteContact(contact.id)">
                  <i
                    class="bi bi-trash-fill text-danger"
                    style="font-size: 1.2rem"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-gray-700">
          Aucun contact associé à cette mairie
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Add contact form modal -->
  <div
    id="addContactSelectModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="addContactSelectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un contact</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form
          @submit.prevent="formContact.id ? updateContact() : createContact()"
        >
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2">Nom</label>
                <input
                  v-model="formContact.first_name"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2">Prénom</label>
                <input
                  v-model="formContact.last_name"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Email</label>
                <input
                  v-model="formContact.email"
                  class="form-control form-control-solid"
                />
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Numéro de téléphone</label>
                <input
                  v-model="formContact.phone_number"
                  v-mask="'00 00 00 00 00'"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Poste</label>
                <select
                  v-if="cityPositions"
                  v-model="formContact.position"
                  class="form-select form-select-solid form-select-lg"
                >
                  <option
                    v-for="position in cityPositions"
                    :key="position.id"
                    :value="position.id"
                  >
                    {{ position.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Personne en contact</label>
                <v-select
                  v-if="usersArray"
                  v-model="ownersSelected"
                  multiple
                  :options="usersArray"
                  class="form-control form-control-solid form-select-lg"
                />
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="fs-5 fw-bold mb-2">Qualité de la relation</label>
                <span class="d-flex text-gray-700">
                  <star-rating
                    :star-size="45"
                    :rating="formContact.rating"
                    @update:rating="formContact.rating = $event"
                  />
                  <button
                    v-if="formContact.rating > 0"
                    class="btn btn-color-danger"
                    @click.prevent="clearRating"
                  >
                    <i
                      class="bi bi-x-circle-fill"
                      style="font-size: 1.2rem"
                    ></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              :disabled="
                formContact.last_name !== '' && formContact.first_name !== ''
                  ? false
                  : true
              "
            >
              {{ formContact.id ? "Modifier" : "Ajouter" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import StarRating from "vue-star-rating";

export default {
  name: "CityContacts",
  components: {
    Loader,
    StarRating,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formContact: {
        id: null,
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        position: "",
        city_id: "",
        owners: "",
        rating: "",
      },
      usersArray: [],
      ownersSelected: [],
    };
  },
  computed: {
    ...mapGetters(["cityContacts"]),
    ...mapGetters(["cityPositions"]),
    ...mapGetters(["users"]),
  },
  mounted() {
    this.getAllCityContacts();
    this.getAllUsers();
    this.getAllCityPositions();
  },
  methods: {
    ...mapActions(["getCityContacts"]),
    ...mapActions(["getCityPositions"]),
    ...mapActions(["postCityContact"]),
    ...mapActions(["deleteCityContact"]),
    ...mapActions(["updateCityContact"]),
    ...mapActions(["getUsers"]),
    ...mapActions(["rateCityContact"]),

    async getAllCityContacts() {
      await this.getCityContacts(this.id);
    },
    async getAllUsers() {
      await this.getUsers();
      if (this.users && this.users.results) {
        this.usersArray = this.users.results.map((user) => {
          return { ...user, label: user.first_name + " " + user.last_name };
        });
      }
    },
    async getAllCityPositions() {
      await this.getCityPositions();
    },
    async createContact() {
      this.formContact.city_id = this.id;
      this.formContact.owners = this.ownersSelected.map((object) => object.id);
      await this.postCityContact(this.formContact);
      await this.getAllCityContacts();
      this.cleanForm();
    },
    async setUpdateContactValues(contact) {
      this.formContact.id = contact.id || "";
      this.formContact.first_name = contact.first_name || "";
      this.formContact.last_name = contact.last_name || "";
      this.formContact.phone_number = contact.phone_number || "";
      this.formContact.email = contact.email || "";
      this.formContact.position = contact.position || "";
      this.formContact.city_id = this.id;
      this.formContact.owners = contact.owners || "";
      this.formContact.rating = contact.relation_quality || "";
      this.ownersSelected = this.usersArray.filter((object) =>
        this.formContact.owners.includes(object.id)
      );
    },
    async updateContact(rating, contactId) {
      this.formContact.city_id = this.id;

      if (contactId) {
        this.formContact.id = contactId;
        this.formContact.rating = rating;
      } else {
        this.formContact.owners = this.ownersSelected.map(
          (object) => object.id
        );
      }

      const res = await this.updateCityContact(this.formContact);

      if (res.status === 200 && !contactId) {
        await this.getAllCityContacts();
      }
      this.cleanForm();
    },
    async deleteContact(id) {
      this.formContact.city_id = this.id;
      await this.deleteCityContact(id);
      await this.getAllCityContacts();
    },
    cleanForm() {
      this.formContact = {
        id: null,
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        position: "",
        city_id: "",
        owners: "",
        rating: "",
      };
      this.ownersSelected = [];
    },
    getUserPosition(id) {
      if (this.cityPositions) {
        let position = null;
        for (let key in this.cityPositions) {
          if (this.cityPositions[key].id === id) {
            position = this.cityPositions[key];
          }
        }
        return position.title;
      }
    },
    clearRating() {
      this.formContact.rating = 0;
    },
  },
};
</script>
